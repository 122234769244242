<template>
    <div>
        <!-- :close-on-click-modal="false" :close-on-press-escape="false" :before-close="handleClose" -->
        <el-dialog :visible.sync="editInfoShow" width="1200px" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="handleClose">
            <div class="state">
                <img src="../../assets/images/serve/gth.png" alt="">
                正在 <span>编辑我的资料</span>
            </div>
            <div class="form">
                <div class="lable">*头像：</div>
                <!-- <img :src="$util.host + info.headPic" v-if="info.headPic!==''" alt=""> -->
                <el-upload :limit="1" :action="action" :file-list="fileList" :on-success="beforeLoad" list-type="picture-card" :on-remove="removeImg">
                    <i class="el-icon-plus"></i>
                </el-upload>
            </div>
            <div class="form">
                <div class="lable">*账户名称：</div>
                <span>{{info.accountKey}}</span>
            </div>
            <div class="form">
                <div class="lable">*个人语录：</div>
                <el-input style="padding: 10px 10px;" :autosize="{ minRows: 4, maxRows: 10}" type="textarea" placeholder="" v-model="input"></el-input>
            </div>
            <!-- 底部按钮 -->
            <div class="foot-btn">
                <el-button class="f-btn" @click="submit">确定</el-button>
            </div>

        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        editInfoShow: Boolean,
        info: Object,
    },
    data() {
        return {
            input: '',
            action: this.$util.upload,
            headPic: "",
            fileList: [],
        };
    },
    created() {
        console.log(this.editInfoShow);

    },
    mounted() {
        console.log(this.info);
        this.input = this.info.quotations
        this.fileList.push({ name: '1', url: this.$util.host + this.info.headPic })
        this.headPic = this.info.headPic
    },
    watch: {
        editInfoShow(val) {
            console.log(val);

        }
    },
    methods: {
        beforeLoad(response, file, fileList) {
            console.log(fileList);
            let imgs = []
            imgs = fileList.map(item => {
                return item.response.data.url
            })
            this.headPic = imgs.join(',')
            console.log(this.headPic);

        },
        removeImg(file, fileList) {
            console.log(fileList);
            let imgs = []
            imgs = fileList.map(item => {
                return item.response.data.url
            })
            this.headPic = imgs.join(',')
        },
        submit() {
            if (this.input == '') {
                return this.$message.error('请输入个人语录!')
            }
            this.$util.post('/center/create/changeMsg', {
                msg: this.input,
                headPic: this.headPic
            }).then(res => {
                if (res.code == 200) {
                    this.handleClose()
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        handleClose() {

            this.$emit('close');

        },
    },
};
</script>

<style lang="scss" scoped>
.state {
    padding: 0px 0 10px;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    font-size: 22px;
    color: #333333;
    span {
        color: #e40012;
    }
    img {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }
}
.form {
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-left: 200px;
    .lable {
        min-width: 100px;
        text-align: right;
        font-size: 16px;
        color: #999999;
        margin-right: 20px;
    }
    span {
        color: #222;
        font-size: 16px;
    }
    img {
        width: 100px;
        height: 100px;
        border-radius: 100%;
    }
}
.foot-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    font-size: 16px;
    cursor: pointer;
    color: #e40012;
    .f-btn {
        padding: 15px 180px;
        background: #e40012;
        color: #fff;
        font-size: 18px;
        margin-left: 40px;
    }
}
</style>