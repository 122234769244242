<template>
    <div>
        <div class="mian-box">
            <div class="main">
                <div class="my-info-title">
                    我的基础资料
                    <div>
                        <span @click="goPost">【我要发帖】</span>
                        <span @click="goCommunity">去论坛</span>
                    </div>
                </div>
                <div class="my-info">
                    <div class="avatar">
                        <img :src="$util.host + info.headPic" alt="">
                    </div>
                    <div class="info">
                        <div class="user-name">
                            {{info.name}} <span @click="edit">编辑资料</span>
                        </div>
                        <div class="info-list">
                            <i class="el-icon-user"></i>
                            <div class="text" v-text="info.accountType == 1?'个人用户':'企业用户'"></div>
                        </div>
                        <div class="info-list">
                            <i class="el-icon-location-outline"></i>
                            <div class="text">{{mapAll[info.province]}}{{mapAll[info.city]}}</div>
                        </div>
                        <div class="info-list">
                            <!-- <img src="../../assets/images/企业微信截图_16593495956596.png" alt=""> -->
                            <div>{{info.quotations}}</div>
                            <!-- <i class="el-icon-message"></i>
                            <div class="text">
                                {{info.industry}}
                                <div>{{info.quotations}}</div>
                            </div> -->
                        </div>
                    </div>
                    <div class="achievement">
                        <div class="achievement-title">
                            <img src="../../assets/images/manage/cj.png" alt="">
                            我的成就榜
                        </div>
                        <div class="achievement-cell">
                            <div>
                                <img src="../../assets/images/manage/ft.png" alt=""> 已发贴
                            </div>
                            {{statistics.send}}
                        </div>
                        <div class="achievement-cell">
                            <div>
                                <img src="../../assets/images/manage/ht.png" alt=""> 已回贴
                            </div>
                            {{statistics.answer}}
                        </div>
                        <div class="achievement-cell">
                            <div>
                                <img src="../../assets/images/manage/dz.png" alt=""> 已点赞
                            </div>
                            {{statistics.fabulous}}
                        </div>
                        <div class="achievement-cell">
                            <div>
                                <img src="../../assets/images/manage/hf.png" alt=""> 已回复
                            </div>
                            {{statistics.reply}}
                        </div>
                    </div>
                </div>
                <div class="foot-main">
                    <!-- 我的关注 -->
                    <div class="my-focus">
                        <div class="focus-head">
                            <div>
                                我的关注 <span>关注数：{{follows.length}}</span>
                            </div>
                            <div>
                                <el-input placeholder="搜索我关注的" prefix-icon="el-icon-search" @input="search" v-model="value">
                                </el-input>
                            </div>
                        </div>
                        <div class="focus-cell" v-for="item in follows" :key="item.id">
                            <div class="user-info">
                                <img @click="toHim(item)" :src="$util.host + item.headPic" alt="">
                                {{item.accountKey}}
                            </div>
                            <div class="material"><span v-text="item.accountType==1?'个人用户':'企业用户'"></span>/{{mapAll[item.province]}}{{mapAll[item.city]}}/{{industryList[item.industry]}}</div>
                            <el-button round class="ro-btn" @click="focusOn(item.id)">已关注</el-button>
                        </div>
                        <!-- 上下页 -->
                        <div class="pagination">
                            <div @click="pre">上一页</div>
                            <div @click="next">下一页</div>
                        </div>
                    </div>
                    <!-- 我的粉丝 -->
                    <div class="my-focus">
                        <div class="focus-head">
                            <div>
                                我的粉丝 <span>粉丝数：{{fans.length}}</span>
                            </div>
                            <div>
                                <el-input placeholder="搜索我粉丝的" prefix-icon="el-icon-search" @input="search2" v-model="keyword">
                                </el-input>
                            </div>
                        </div>
                        <div class="focus-cell" v-for="item in fans" :key="item.id">
                            <div class="user-info">
                                <img @click="toHim(item)" :src="$util.host +item.headPic" alt="">
                                {{item.accountKey}}
                            </div>
                            <div class="material"><span v-text="item.accountType==1?'个人用户':'企业用户'"></span>/{{mapAll[item.province]}}{{mapAll[item.city]}}/{{industryList[item.industry]}}</div>
                            <el-button round class="ro-btn" v-if="!infoId.includes(item.id)" @click="focusOn(item.id)">关注TA</el-button>
                            <el-button round class="ro-btn" v-else>已关注</el-button>
                            <!-- v-if="!info.includes(userInfo[0].id)" -->
                        </div>
                        <!-- 上下页 -->
                        <div class="pagination">
                            <div @click="pre2">上一页</div>
                            <div @click="next2">下一页</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <EditInfo v-if="editInfoShow" :editInfoShow="editInfoShow" @submit="proSubmit" :info="info" @close="close" />
        </div>
    </div>
</template>

<script>
import EditInfo from "../../components/manage/editInfo.vue";
export default {
    components: { EditInfo },
    data() {
        return {
            value: "", //关注搜索值
            editInfoShow: false, //修改个人语录弹出层
            info: {}, //个人基础信息
            mapAll: {}, //获取所有数据.
            statistics: {}, //关注 ,粉丝 数量列表
            fans: [], //粉丝列表
            pageNo: 1, //关注页数
            pageNo2: 1, //粉丝页数
            keyword: '', //粉丝搜索值
            follows: [], //关注列表
            industryList: {
                'A': '农、林、牧、渔业',
                'B': '采矿业,',
                'C': '制造业',
                'D': '电力、热力、燃气及水生产和供应业',
                'E': '建筑业',
                'F': '批发和零售业',
                'G': '交通运输、仓储和邮政业',
                'H': '住宿和餐饮业',
                'I': '信息传输、软件和信息技术服务业',
                'J': '金融业',
                'K': '房地产业',
                'L': '租赁和商务服务业',
                'M': '科学研究和技术服务业',
                'N': '水利、环境和公共设施管理业',
                'O': '居民服务、修理和其他服务业',
                'P': '教育',
                'Q': '卫生和社会工作',
                'R': '文化、体育和娱乐业',
                'S': '公共管理、社会保障和社会组织',
                'T': '国际组织',
            }, //行业.
            timeout: null,  //防抖搜索
            timeout2: null,//防抖搜索
            infoId: [],//关注的id
        };
    },
    mounted() {


        this.getInfo()
        this.getAllMap()
        this.getStatistics()
        this.getFans()
        this.getFollow()
        // console.log(this.info);

    },
    methods: {
        toHim(val) {
            this.$router.push({ path: `/himCreate?id=${val.id}` })
        },
        edit() {
            // console.log(11);
            this.editInfoShow = true
        },
        // 获取个人信息
        getInfo() {
            this.$util.post('/user/info').then(res => {
                console.log(res);
                this.info = res.data
                this.infoId = res.data.fabulousUid.split(",")

            })
        },
        // 获取个人粉丝关注回复量
        getStatistics() {
            this.$util.post('/center/create/statistics').then(res => {
                console.log(res);
                this.statistics = res.data
            })
        },
        // 获取所有地址
        getAllMap() {
            this.$util.post('/address/allMap').then(res => {
                this.mapAll = res.data.all
            })
        },
        // 粉丝列表
        getFans() {
            this.$util.post('/center/create/followMe', {
                uName: this.keyword,
                pageNo: this.pageNo2
            }).then(res => {
                console.log(res);
                this.fans = res.data
            })
        },
        // 上一页
        pre2() {
            if (this.pageNo2 == 1) {
                return this.$message.error('当前已经是第一页了')
            }
            this.pageNo2--
            this.getFans()
        },
        // 下一页
        next2() {
            if (this.fans.length == 0) {
                return this.$message.error('当前已经是最后一页了')
            }
            this.pageNo2++
            this.getFans()
        },
        // 关注列表
        getFollow() {
            this.$util.post('/center/create/myFollow', {
                uName: this.value,
                pageNo: this.pageNo
            }).then(res => {
                console.log(res);
                if (res.data == undefined) {

                } else {
                    this.follows = res.data
                    // console.log(res.data[0].includes(57587276));

                }

            })
        },
        // 上一页
        pre() {
            if (this.pageNo == 1) {
                return this.$message.error('当前已经是第一页了')
            }
            this.pageNo--
            this.getFollow()
        },
        // 下一页
        next() {
            if (this.follows.length == 0) {
                return this.$message.error('当前已经是最后一页了')
            }
            this.pageNo++
            this.getFollow()
        },
        // 关注
        focusOn(id) {
            this.$util.post('/user/fabulous', {
                uid: id
            }).then(res => {
                if (res.code == 200) {
                    this.getInfo()
                    this.getStatistics()
                    this.getFans()
                    this.getFollow()
                } else {
                    this.$message.error(res.message)
                }

            })
        },
        // 搜索
        search() {
            this.pageNo = 1
            if (this.timeout) {
                clearTimeout(this.timeout)
            }
            this.timeout = setTimeout(() => {
                this.getFollow()
            }, 500);

        },
        // 搜索粉丝
        search2() {
            this.pageNo2 = 1
            if (this.timeout2) {
                clearTimeout(this.timeout2)
            }
            this.timeout2 = setTimeout(() => {
                this.getFans()
            }, 500);

        },
        proSubmit() {
            this.editInfoShow = false
        },
        close() {
            this.editInfoShow = false
            this.getInfo()
        },
        // 去论坛
        goCommunity() {
            this.$router.push({ path: '/community' })
        },
        goPost() {
            this.$router.push({ path: '/post' })

        }
    },
};
</script>

<style lang="scss" scoped>
.mian-box {
    background: #f6f6f6;
    padding: 20px 0 100px;
}
.main {
    width: 1200px;
    margin: 0 auto;
}
.my-info-title {
    display: flex;
    padding: 30px;
    background: #fff;
    font-size: 22px;
    color: #222222;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;

    span {
        font-size: 16px;
        margin-left: 40px;
        cursor: pointer;
    }
}
.my-info {
    padding: 20px 30px 40px;
    background: #fff;
    margin: 10px 0 40px;
    display: flex;
    .avatar {
        width: 100px;
        height: 100px;
        // background: red;
        img {
            border-radius: 100%;
            width: 100%;
            height: 100%;
        }
    }
    .info {
        flex: 1;
        margin: 0 30px;
        font-size: 18px;
        color: #222;
        .user-name {
            display: flex;
            span {
                color: #2ea7e0;
                margin-left: 50px;
                cursor: pointer;
            }
        }
        .info-list {
            display: flex;
            // align-items: center;
            margin-top: 30px;
            font-weight: 400;
            i {
                font-size: 20px;
                margin-top: 2px;
            }
            .text {
                margin-left: 10px;
                div {
                    margin-top: 20px;
                }
            }
        }
    }
    .achievement {
        width: 310px;
        font-size: 16px;
        color: #222;
        .achievement-title {
            display: flex;
            padding-bottom: 10px;
            border-bottom: 1px solid #eeeeee;
            align-items: center;
            img {
                width: 15px;
                height: 17px;
                margin-right: 5px;
            }
        }
        .achievement-cell {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 30px;
            div {
                display: flex;
                align-items: center;
                img {
                    margin-right: 12px;
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
}
.foot-main {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    .my-focus {
        position: relative;
        width: 530px;
        max-height: 1220px;
        min-height: 500px;
        background: #fff;
        padding: 30px;
        .focus-head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 20px;
            padding-bottom: 30px;
            color: #222;
            span {
                font-size: 16px;
                color: #999999;
                margin-left: 20px;
            }
        }
    }
    .focus-cell {
        display: flex;
        align-items: center;
        padding: 30px 0;
        border-top: 1px solid #eeeeee;
        justify-content: space-between;
        color: #222;
        .user-info {
            display: flex;
            align-items: center;
            font-size: 20px;
            img {
                width: 60px;
                height: 60px;
                border-radius: 100%;
                margin-right: 20px;
            }
        }
        .material {
            max-width: 220px;
            font-size: 16px;
        }
        .ro-btn {
            width: 97px;
            height: 40px;
            border: 1px solid #e40012;
            color: #e40012;
            background: #fff;
            font-size: 14px;
        }
    }
    .pagination {
        display: flex;
        justify-content: space-between;
        color: #2ea7e0;
        font-size: 14px;
        position: absolute;
        bottom: 30px;
        left: 30px;
        right: 30px;
        div {
            cursor: pointer;
        }
    }
}
</style>